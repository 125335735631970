.report-message-parent-container-selector .report-message-email-field {
    background: white;
    border-radius: 10px;
}

.report-message-parent-container-selector .report-message-email-field {
    margin-bottom: 10px !important;
}

.report-message-parent-container-selector .form-for-reporting-messege .MuiInputBase-formControl:before,
.report-message-parent-container-selector .form-for-reporting-messege .MuiInputBase-formControl:after {
    border-bottom: none !important;

}

.report-message-parent-container-selector .contact-us-message {
    background: white;
    border-radius: 10px;
}


.report-message-parent-container-selector .MuiInputBase-colorPrimary {
    background-color: white !important;
}

.report-message-parent-container-selector .MuiPaper-rounded {
    box-shadow: none !important;
}

.report-message-parent-container-selector .MuiInputBase-input {
    color: var(--sl-deep-purple) !important;
}

.report-message-parent-container-selector .MuiOutlinedInput-notchedOutline {
    border-color: none !important;
    border-width: 0px !important;
}

.report-message-parent-container-selector .MuiInputBase-formControl {
    border-radius: 10px !important;
    font-family: Figtree;
}

.report-message-parent-container-selector .MuiOutlinedInput-input {
    font-family: Figtree !important;

}

.report-message-parent-container-selector .contact-us-message .MuiFilledInput-input {
    padding-left: 0px !important;
    position: relative;
    /* right: 4px; */
}

.report-message-parent-container-selector .contact-us-message .Mui-error {
    color: var(--sl-deep-purple) !important;
}

.report-message-parent-container-selector .MuiInputLabel-root {
    color: #514C54 !important;
}

.report-message-parent-container-selector .MuiInputLabel-root,
.report-message-parent-container-selector .MuiInputBase-input {
    font-family: Figtree;
    font-size: 17px;
    font-weight: 500;
}

.report-message-parent-container-selector .MuiInputBase-root {
    border-radius: 10px;
    border: 1px solid var(--colorLightGray);
}