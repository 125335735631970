.search-input-full-width .search-input {
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 10px !important;
    box-sizing: border-box;
}

@media screen and (max-width:480px) {
    .search-input-full-width .search-input {
        margin-top: 25px !important;
    }
}