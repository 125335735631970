.white-color {
    color: var(--colorWhite)  !important;
    background-color: transparent !important;
}
.textFieldGeneric  .MuiInputLabel-standard,
.textFieldGeneric .MuiFormLabel-root.Mui-focused {
    color: var(--colorWhite) !important;
}

.Mui-error{
    color: var(--colorRed) !important;
}

.MuiInputBase-root {
    color: var(--colorWhite) !important;
}
/* .MuiInputBase-root:after,
.MuiInputBase-root:before {
    border-bottom: 1px solid var(--colorWhite) !important;
} */
.textFieldGeneric .MuiInputBase-formControl:after,
.textFieldGeneric .MuiInputBase-formControl:before
{
    border-bottom: 1px solid var(--colorWhite) !important;

}
.deletePassInput{
    background: #FFFFFF;
    border: 1px solid #CBC8CD;
    border-radius: 10px;
}
