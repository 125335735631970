@media screen and (max-width: 1950px) {
    .react-joyride__spotlight{
    margin-top:0px;
    }
    }
    
    @media screen and (max-width: 1750px) {
      .react-joyride__spotlight{
      margin-top:0px;
      margin-left:0px;
      }
    }
    @media screen and (max-width: 1700px) {
      .react-joyride__spotlight{
      margin-top:0px;
      margin-left:0px;
      }
    }
    @media screen and (max-width: 1550px) {
      .react-joyride__spotlight{
        margin-top: 0px;
        margin-left: 0px;
      }
    }
    @media screen and (max-width: 1450px) {
      .react-joyride__spotlight{
      margin-top:0px;
      margin-left:0px;
      }
    }
    @media screen and (max-width: 1420px) {
      .react-joyride__spotlight{
      margin-top:0px;
      margin-left:0px;
      }
    }
    @media screen and (max-width: 1370px) {
      .react-joyride__spotlight{
      margin-top:0px;
      margin-left:0px;
      }
    }
    @media screen and (max-width: 1280px) {
      .react-joyride__spotlight{
      margin-top:0px;
      margin-left:0px;
      }
    }