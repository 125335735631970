.MuiInputLabel-standard {
  color: var(--colorWhite) !important;
}

.MuiInputBase-root {
  color: var(--colorWhite) !important;
}

.MuiInputBase-root:after,
.MuiInputBase-root:before {
  border-bottom: 1px solid var(--colorWhite) !important;
}

/* .MuiIconButton-root{
    color: var(--colorWhite) !important;
  } */

.css-1nr64t2-MuiFormLabel-root.Mui-focused {
  color: var(--colorWhite) !important;
}

.MuiFormControlLabel-label {
  margin-left: 6px;
}

.radio-checked > span {
  color: var(--colorWhite) !important;
}

.blur-backdrop {
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 30, 0.4);
  .MuiPaper-root {
    background: var(--bg-gradient);
    border-radius: 10px;
    border: 1px solid #dbdee4;
    padding: 2rem;
    width: 300px;
    z-index: 9;
  }
}

.set-placeholder {
  width: 100% !important;
}

input[type="radio"] {
  display: none;
}

label {
  cursor: pointer;
  color: #555;
}

.datepicker-placeholder .MuiInputBase-root.MuiInput-root > input::placeholder {
  text-transform: uppercase;
}

input[type="radio"] + label span {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  border: 1px solid var(--colorLightGray);
  border-radius: 10px;
  background-color: #ffffff;
  text-align: center;
}

input[type="radio"][disabled] + label span {
  background-color: #ececec;
  border: #ececec;
}

input[type="radio"][active] + label span {
  background-color: var(--colorWhite);
}

input[type="radio"][active] + label span:hover {
  background-color: var(--colorWhite);
  border: 1px solid var(--colorLightGray);
}

input[type="radio"] + label span:focus {
  background-color: #ffffff;
}

input[type="radio"]:not([active]):not([disabled]):not(:checked)
  + label
  span:focus::before {
  content: "";
  display: inline-block;
  width: 20%;
  height: 20%;
  border-radius: 50%;
  background-color: var(--colorWhite);
  border: 1px solid var(--colorLightGray);
}

input[type="radio"] + label span {
  border-radius: 50% !important;
  /* margin-right: 7px !important; */
}

input[type="radio"][disabled] + label span::before,
input[type="radio"]:checked + label span::before {
  content: "⬤";
  color: var(--sl-purple);
  font-size: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  position: relative;
  left: auto;
}

input[type="radio"]:checked + label span {
  background-color: var(--colorWhite);
  text-align: center;
}

.radio-style {
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: normal !important;
  color: var(--colorWhite) !important;
}

.city-selection-list .MuiOutlinedInput-notchedOutline {
  border: none;
}

/* .city-selection-list .MuiInputLabel-root .Mui-focused{
  color: white !important;

} */
.city-selection-list .MuiInputLabel-formControl {
  left: -15px;
}

.city-selection-list .MuiInputBase-input {
  padding-left: 0px !important;
  padding-top: 20px;
  padding-bottom: 4px !important;
}

.city-selection-list .MuiInputLabel-shrink {
  color: white !important;
  top: 12px;
  left: -13.5px;
}

.city-selection-list .demo-multiple-name {
  padding-left: 0px !important;
  margin-top: 12px;
  position: relative !important;
  top: 8px !important;
  left: -12px !important;
}

.city-selection-list .MuiSelect-icon {
  color: white !important;
}

.city-selection-list .MuiSelect-icon {
  right: 0px;
}

.datepicker-placeholder .MuiInputLabel-animated {
  font-family: Figtree;
}

.set-placeholder .MuiInputLabel-animated {
  font-family: Figtree;
}

/* .hide-city-list .MuiPaper-rounded{
  display: none !important;
} */
.vertical-scroll-handling::-webkit-scrollbar {
  width: 8px;
  /* Set the width of the scrollbar */
}

.disable {
  pointer-events: none;
  opacity: 0.4;
}
.additionalAccountInfo .Mui-focused {
  color: white !important;
}
