/* .delete-dialog-style {
    position: absolute;
    width: 400px;
    /* left: 37.8%;
    right: 40%;
    top: 27%;
    bottom: 20%;
    max-height: 390px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 35px;
    background: linear-gradient(180deg, #F0E1D9 0%, #EFF2F3 58.85%);
    border-radius: 10px;
} */
.profile-delete-btn {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 600;
    /* font-size: 15px; */
    line-height: 18px;
}
#delete-account-modal {
    align-items: center;
    display: flex;
    justify-content: center;
}
/* .sl-button>p {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0;
} */

@media screen and (min-width: 1199px) {
    .delete-dialog-style{
        left: 30%;
    }
}