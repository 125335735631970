.faq-accordion.MuiPaper-root {
  margin: 0;
  border: unset;
  box-shadow: none;
}

.faq-card .MuiCardContent-root,
.faq-card .MuiCardContent-root:last-child {
  padding: 20px;
  padding-bottom: 20px;
}

.faq-accordion.MuiPaper-root.Mui-expanded {
  margin: 0;
  border: unset;
}

.faq-accordion .MuiAccordionSummary-content,
.faq-accordion .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
  padding: 8px 16px;
}

.faq-accordion .MuiAccordionDetails-root {
  padding: 8px 16px;
}
