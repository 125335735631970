@media screen and (min-width: 1200px) {
  .sticky-bottom-footer .main-container {
    position: fixed !important;
    bottom: 10px !important;
    width: 93% !important;
    left: 3.2%;
  }
}

@media screen and (max-width: 950px) {
  .remember-txt {
    left: 30%;
  }
}

.forgotPassword-dialog-box {
  justify-content: center;
  align-items: center;
  padding: 30px 37px;
  gap: 20px;
  background: var(--bg-gradient) !important;
}

.mainDialog-box {
  justify-content: left;
  align-items: left;
  padding: 25px 100px 17px 17px;
  gap: 20px;
  background: var(--bg-gradient);
}

.sticky-bottom-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
