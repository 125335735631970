.ciruclarProgressBar {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 4px solid #4b2b5a;
  transform: rotate(359deg);
  position: relative;
  margin: auto;
}
.dailyMeditationStats h2 {
  margin-bottom: 20px;
}
.ciruclarProgressBar:after {
  position: absolute;
  border-radius: 53%;
  width: 12px;
  height: 6px;
  background-color: white;
  content: " ";
}
.ciruclarProgressBar:after {
  right: 72px;
  top: -5px;
}
.circleEdge {
  width: 6px;
  height: 3.9px;
  text-align: center;
  left: 47%;
  position: relative;
  top: -3.88px;
  background: #4b2b5a;
  z-index: 999;
  border-radius: 10px;
}
.circleEdge2 {
  width: 6px;
  height: 3.9px;
  text-align: center;
  left: 53%;
  position: relative;
  top: -7.58px;
  background: #4b2b5a;
  z-index: 999;
  border-radius: 10px;
}
.recharts-text {
  font-family: "Figtree";
  font-size: 17px;
  font-weight: 600;
}
.recharts-pie {
  outline: none;
}
/* daily meditation popup styles */
.dailyMeditationGoalPopup .MuiDialog-paperWidthSm {
  margin: 12px;
}
.dailyMeditationGoalPopup .css-1ysjc0z {
  padding-left: 12px;
  padding-right: 12px;
}
