.mainDialog-box {
  justify-content: left;
  align-items: left;
  padding: 25px 100px 17px 17px;
  gap: 20px;
  background: var(--bg-gradient);
}

/* .city-selection-list .MuiOutlinedInput-notchedOutline{
    border: none;

}

.city-selection-list .MuiInputLabel-formControl{
    left: -15px;
}
.city-selection-list .MuiInputBase-input{
    padding-left: 0px !important;
    padding-top: 20px;
    padding-bottom: 4px !important;
}
.city-selection-list .MuiInputLabel-shrink{
    color: white !important;
    top: 12px;
    left: -13.5px;
}
.city-selection-list  .demo-multiple-name{
    padding-left: 0px !important;
    margin-top: 12px;
    position: relative !important;
    top: 8px !important;
    left: -12px !important;
}
.city-selection-list .MuiSelect-icon{
    color: white !important
} */

.logInScreenStyles .MuiInputLabel-formControl {
  font-family: Figtree !important;
}
.logInScreenStyles .sl-button > p {
  font-size: 15px !important;
}
