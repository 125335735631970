.background-color-change .MuiFormHelperText-root {
  color: var(--sl-purple) !important;
  margin-left: 0.5rem !important;
}

.mainDialog-box {
  justify-content: left !important;
  align-items: left !important;
  padding: 25px 100px 17px 17px !important;
  gap: 20px !important;
  background: var(--bg-gradient) !important;
}

@media screen and (min-width: 1200px) and (min-height: 620px) {
  .sticky-bottom-footer .main-container {
    position: fixed !important;
    bottom: 10px !important;
    width: 93% !important;
    left: 3.2%;
  }
}
