.mediation-card {
    width: 150px;
    height: 230px;
    background-size: cover;
    background-position: center;
    margin-bottom: 10px;
    max-width: 150px;
    max-height: 250px;
    min-width: 150px;
    max-height: 250px;
    border-radius: 10px;
    margin-left: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.mediation-data {
    padding-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0;
}

.mediation-time {
    display: flex;
    justify-content: space-between;
}

.selected-icon {
    margin-top: 50%;
    margin-left: 36%;
}

.without-image {
    height: 40px;
}

.mediation-name {
    color: var(--colorWhite);
    text-align: left;
    font-style: normal !important;
    line-height: 18px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    font-family: 'Figtree' !important;
    /* height: auto !important; */
}

/* .mediation-card{
    background-image: url("https://d2iouuu86go54j.cloudfront.net/images/original_JlxgaB9mo2EviRAXoW6ez09kDeFmly7R");
} */
.mediation-icon {
    width: 15px;
    height: auto;
    margin-right: 10px;
    cursor: pointer;
    z-index: 99999;
}

.mediation-image {
    display: flex;
    justify-content: center;
}