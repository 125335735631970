
.donation-column .checked-border {
    box-shadow: 0 0 0 3px white;
}

.donation-column .MuiOutlinedInput-notchedOutline{
    border: none !important;
}

.donation-column .MuiInputLabel-shrink{
    top: 16px !important;
    padding-top: 8px;
}
.donation-column .Mui-focused{
color: #514C54 !important;
/* padding-top: 10px;   */
}
.donation-column .MuiInputLabel-outlined{
    top: 5px;
}
.donation-column .MuiOutlinedInput-root{
    height: 60px;
}
#cover-spin {
    width:15px;
  }
.subscribeBtn{
    height: 50px;
    color: white !important;
    text-transform: capitalize !important;
    width: 200px !important;
    border-radius: 25px !important;
}
  @-webkit-keyframes spin {
  from {-webkit-transform:rotate(0deg);}
  to {-webkit-transform:rotate(360deg);}
  }

  @keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
  }

  #cover-spin::after {
    content: '';
      display: block;
      /* position: absolute; */
      left: 73px;
      top: 40%;
      width: 22px;
      height: 22px;
      border-style: solid;
      border-color: white;
      border-top-color: transparent;
      border-width: 3px;
      border-radius: 50%;
      animation: spin .8s linear infinite;
  }
@media screen and (max-width: 1400px) {
    .donation-column-button-stack {
        padding-bottom: 9.8% !important;
    }
}

@media screen and (max-width: 1250px) {
    .donation-column-button-stack {
        padding-bottom: 9.7% !important;
    }
}

@media screen and (max-width: 1024px) {
    .donation-column-button-stack {
        padding-bottom: 26.2% !important;
    }
}
.disablebtn{
    opacity: 0.5;
    cursor: default !important;
}
/* .subscribeBtn{
    opacity: 0.8;
} */