.current-Meditations{
    position:relative
}
.current-Meditations span{
    position: absolute;
    top: 19px;
    left: 21px;
    font-weight: 700;
}
.menu-Container{
    width: 83%;
    display: flex;
    justify-content: flex-end;
    gap: 30px;
    position: relative;
    z-index: 1;
    align-items: center;
}
@media screen and (max-width: 1100px) {
    .menu-Container{
        width: 100%;
    }
}