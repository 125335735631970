.fill-heart {
    fill: white !important;
    outline: none;
    cursor: pointer;
    width: 16px !important;
    height: 14.65px !important;
}
.empty-heart{
    color: white;
    outline: none;
    cursor: pointer;
    width: 16px !important;
    height: 14.65px !important;
}
.empty-purple-heart{
    fill: var(--sl-deep-purple) !important;
    outline: none;
    cursor: pointer;
}
.fill-purple-heart{
    fill: var(--sl-deep-purple) !important;
    outline: none;
    cursor: pointer;
}