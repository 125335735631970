.sidebar-container {
  height: 100%;
  display: inline-table;
}

.navbar-container {
  scrollbar-width: none;
}

.header-profile-icon img {
  height: 50px;
}

.current-Meditation-Container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.current-Meditations {
  background-image: url(../../assets/images/currentMediations.svg);
  background-size: cover;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-family: "Figtree";
  font-weight: 600;
}

.guestHeader {
  position: relative;
  width: 130px;
  right: 32px;
  display: flex;
}

#covers-spin {
  width: 15px;
}

#covers-spin::after {
  content: '';
  display: block;
  /* position: absolute; */
  left: 68px;
  top: 40%;
  width: 22px;
  height: 22px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 2px;
  border-radius: 50%;
  animation: spin .8s linear infinite;
  margin-left: -6px;
}