.meditationDetails .slick-next,
.meditationDetails .slick-prev {
  top: -35px !important;
}
.meditationDetails .myCreatedMeditation .slick-next {
  right: 0px !important;
}
.meditationDetails .myCreatedMeditation .slick-prev {
  right: 20px !important;
}
@media screen and (min-width: 1280px) {
  .react-joyride__spotlight {
    margin-top: 0px;
    margin-left: 0px;
  }
}
