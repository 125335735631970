.search-input {
  width: auto !important;
  max-width: 500px !important;
  height: 50px !important;
  background: #ffffff !important;
  border: 1px solid #cbc8cd !important;
  border-radius: 30px !important;
  padding-left: 17px !important;
  /* margin-top: 40px !important; */
  margin-bottom: 44px !important;
}
.search-input .MuiInputBase-adornedStart > input {
  color: var(--sl-deep-purple) !important;
}
.search-input .MuiInputBase-adornedStart {
  height: inherit !important;
}
.search-input .MuiInputBase-adornedStart > input::placeholder {
  color: black !important;
  text-transform: capitalize !important;
}
.MuiToggleButton-root.Mui-selected .MuiCard-root .media-image {
  background-image: none !important;
}
.aqua-toggle.MuiToggleButtonGroup-root,
.deeppurple-toggle.MuiToggleButtonGroup-root {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.deeppurple-toggle .MuiToggleButton-root.Mui-selected,
.deeppurple-toggle .MuiToggleButton-root.Mui-selected:hover {
  background-color: var(--sl-deep-purple) !important;
}
.aqua-toggle .MuiToggleButton-root.Mui-selected,
.aqua-toggle .MuiToggleButton-root.Mui-selected:hover {
  background-color: var(--sl-aqua-contrast) !important;
}
.deeppurple-toggle .MuiToggleButton-root {
  border-radius: 20px !important;
  border: 1px solid var(--colorLightGray) !important;
  color: var(--sl-deep-purple) !important;
  height: 30px !important;
  padding: 8px 13px;
}
.aqua-toggle .MuiToggleButton-root {
  color: var(--sl-aqua-contrast) !important;
  padding: 0;
  border-radius: 50% !important;
  border: unset !important;
}
.aqua-toggle .MuiToggleButton-root,
.deeppurple-toggle .MuiToggleButton-root {
  font-size: 12px;
  font-weight: 500;
  min-width: 60px;
  text-transform: capitalize;
  line-height: 13px;
}
.aqua-toggle .MuiToggleButton-root span,
.deeppurple-toggle .MuiToggleButton-root span {
  pointer-events: none;
  user-select: none;
}
.aqua-toggle .MuiToggleButton-root.Mui-selected .min-value,
.MuiToggleButton-root.Mui-selected,
.MuiToggleButton-root.Mui-selected:hover {
  color: var(--colorWhite) !important;
}
.aqua-toggle .number-style-change {
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 20px !important;
  color: var(--sl-aqua-contrast) !important;
}
.content2::before {
  content: "\a";
  white-space: pre;
}
.aqua-toggle .min-value {
  color: var(--sl-aqua-contrast);
  text-transform: lowercase !important;
}
.card-skeleton {
  width: 150px;
  height: 230px;
  max-height: 250px;
  border-radius: 10px;
  background-color: rgb(188, 186, 186);
  -webkit-mask: linear-gradient(
      -60deg,
      white 30%,
      rgb(226, 225, 225),
      white 70%
    )
    right/350% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
}
.joyride-target-toggle .MuiToggleButton-root.btnBorderColor {
  border-color: #ffffff !important;
  border-left: 1px solid #ffffff !important;
}
.meditationListingContainer .MuiGrid-container {
  display: none;
}
.meditationListingContainer > div {
  margin-left: 0px !important;
}
.meditationListingContainer .infinite-scroll-component {
  height: auto;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  position: relative;
}
.loaderContainerListing {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 107px;
  display: flex;
  z-index: 9999;
  top: -20px;
}
.loaderContainerListing .loaderContainerListing2 {
  position: relative;
}
.loaderContainerListing .MuiAvatar-circular {
  width: 71px;
  height: 71px;
  display: inline-block;
  box-sizing: border-box;
}
.loaderContainerListing::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #67a3b2;
  border-top-color: transparent;
  animation: spin 1s infinite linear;
  width: 75px;
  height: 75px;
  margin-top: -4px;
}
@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
