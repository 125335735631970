body {
  font-family: "Figtree";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 2px;
}

.mt-2 {
  margin-top: 5px;
}

.mt-3 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 15px;
}

.mt-6 {
  margin-top: 25px !important;
}

.mt-7 {
  margin-top: 40px !important;
}

.mt-8 {
  margin-top: 55px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  margin-bottom: 2px;
}

.mb-2 {
  margin-bottom: 5px;
}

.mb-3 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 10px;
}

.mb-5 {
  margin-bottom: 15px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-1 {
  margin-left: 2px;
}

.ml-2 {
  margin-left: 5px;
}

.ml-3 {
  margin-left: 8px;
}

.ml-4 {
  margin-left: 10px;
}

.ml-5 {
  margin-left: 15px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-1 {
  margin-right: 2px;
}

.mr-2 {
  margin-right: 5px;
}

.mr-3 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 15px;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.d-inline-block {
  display: inline-block;
}

.justify-space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.d-none {
  display: none;
}

.p-relative {
  position: relative;
}

.meditationErrorField {
  width: 405px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px;
}

.line-clamp {
  display: -webkit-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.line-clamp-2 {
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  line-clamp: 3;
  -webkit-line-clamp: 3;
}

.line-clamp-5 {
  line-clamp: 5;
  -webkit-line-clamp: 5;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MuiToggleButtonGroup-root .MuiButtonBase-root {
  font-family: "Figtree";
  font-weight: 600;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.background-color-change {
  background-image: none !important;
  background: var(--bg-gradient) !important;
  color: var(--sl-deep-purple);
}

.text-left {
  text-align: left;
}

.forgot-password-email-input {
  color: var(--sl-deep-purple);
  width: auto !important;
  margin: 0 !important;
  margin-top: 15px !important;
}

.App-link {
  color: #61dafb;
}

/* .slick-dots {
  bottom: -163px;
} */
/* .slick-list {
  height: 321px;

}

.slick-dots li {
  margin: 0;
} */

/* signup page css */

.label-input {
  /* color: black !important; */
  text-align: left;
  margin-top: 15px;
  margin-bottom: 4px;
}

.sl-input.MuiFormControl-root {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid var(--colorLightGray);
  margin-top: 10px;
  height: 50px;
}

.sl-input.MuiFormControl-root .MuiInput-input,
.sl-input.MuiFormControl-root .MuiOutlinedInput-input {
  font-family: "Figtree";
  font-weight: 500;
  font-size: 17px;
  color: var(--sl-deep-purple);
  position: relative;
  top: 3px;
}

.sl-input.MuiFormControl-root fieldset {
  border: none;
}

.sl-input.MuiFormControl-root .MuiInputLabel-focused,
.sl-input.MuiFormControl-root .MuiInputLabel-outlined,
.sl-input.MuiFormControl-root .Mui-focused.MuiInputLabel-formControl,
.sl-input.MuiFormControl-root .MuiFormLabel-root {
  font-family: "Figtree";
  color: var(--sl-light-text);
}

.sl-input.MuiFormControl-root .MuiFormLabel-root {
  top: -2px;
}

.sl-input .MuiFormLabel-root.Mui-focused,
.sl-input.MuiFormControl-root .MuiInputLabel-root.Mui-focused,
.sl-input.MuiFormControl-root .MuiInputLabel-shrink {
  color: var(--sl-light-text);
  top: 12px;
  font-size: 17px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  box-shadow: unset;
  border-radius: 10px;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  padding: 0;
}

.MuiButtonBase-root.MuiMenuItem-root {
  font-family: Figtree;
  padding: 10px 16px;
  color: var(--sl-deep-purple);
}

.additional-text {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: white;
}

.input-field {
  position: relative;
  width: 100%;
  height: 45px;
  background: #f8f8f8;
  border: 0px solid #ced4da;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 20px !important;
  border-radius: 7px !important;
  /* color: #697382; */
  /* margin-top: 5px;
  margin-bottom: 20px; */
}

.password-field:hover,
.password-field:focus {
  box-shadow: red !important;
  outline: none;
  border: none;
}

.title-txt {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height, or 122% */

  text-align: center;

  /* Black */

  color: #262626;
}

/* Dialog box Css */
.dialog-box {
  justify-content: center;
  align-items: center;
  padding: 35px 87px;
  gap: 20px;
  background: #ffffff;
  border-radius: 12px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.dialog-txt {
  margin: 0;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px !important;
}

.dialog-signup-btn {
  align-items: center;
  padding: 22px 110px;
  gap: 10px;

  width: 280px;
  height: 64px;

  /* Light Blue */

  background-color: #a3afce !important;
  border: 1px solid #a3afce !important;
  border-radius: 8px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 100% !important;
}

/* .MuiPaper-root.MuiDialog-paper {
  max-width: 999px !important ;
} */

.checkbox-icon {
  width: 26px !important;
  height: 26px !important;
  color: #a3afce;
}

.rws-header,
.rws-icons button[aria-label="reddit"],
.rws-icons button[aria-label="telegram"],
.rws-icons button[aria-label="linkedin"] {
  display: none;
}

.forgot-password-email-input .MuiInputLabel-standard,
.forgot-password-email-input .MuiFormLabel-root,
.forgot-password-email-input .MuiFormLabel-root.Mui-focused {
  color: var(--sl-deep-purple) !important;
  left: 4px;
  font-family: "Figtree" !important;
}

.forgot-password-email-input .MuiInputBase-root > input {
  padding-left: 15px;
  font-family: Figtree !important;
  font-size: 17px;
  font-weight: 500;
}

/* .MuiInputBase-input .MuiInputBase-input::placeholder {
  color: white !important;
} */

.forgot-password-email-input .MuiInputBase-root,
.forgot-password-email-input .MuiInputBase-root:hover {
  color: var(--sl-deep-purple) !important;
  border: 1px solid #cbc8cd;
  background-color: white;
  border-radius: 10px;
}

.forgot-password-email-input .MuiInputBase-root.Mui-focused {
  color: var(--sl-deep-purple) !important;
  border: 1px solid #cbc8cd;
  background-color: white;
  border-radius: 10px;
}

.forgot-password-email-input .MuiInputBase-root:after,
.forgot-password-email-input .MuiInputBase-root:before {
  border-bottom: 1px solid var(--sl-deep-purple) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

video::-webkit-media-controls-enclosure {
  display: none !important;
}

button[data-action="skip"] {
  position: absolute;
  top: 0px;
  right: 5px;
}

.react-joyride__tooltip {
  font-family: "Figtree";
}

.react-joyride__tooltip div div {
  padding: 0px !important;
}

.react-joyride__tooltip .bodyTextTutorial {
  margin: 0px;
  font-size: 16px;
  text-align: left;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 20px;
  color: var(--sl-deep-purple);
}

.tutorialHeading {
  text-align: left;
  font-size: 20px;
  color: var(--sl-deep-purple);
  margin-bottom: 5px;
  margin-top: 5px;
}

.react-joyride__tooltip button[data-action="primary"] {
  background-color: #3e7e96 !important;
  border-radius: 20px !important;
  border: none !important;
  font-family: "Figtree";
  font-size: 14px !important;
  padding: 12px 20px !important;
  outline: none !important;
}

.footerTextTutorial {
  position: absolute;
  font-size: 14px;
  color: var(--colorDarkGrayContrast);
  font-family: "Figtree";
  margin: 0px;
  bottom: 25px;
  left: 16px;
  font-weight: 500;
}

.react-joyride__tooltip button[data-action="back"] {
  display: none;
}

.lastStepBtn {
  background-color: transparent !important;
  border: 0px !important;
  border-radius: 0px !important;
  color: rgb(51, 51, 51) !important;
  cursor: pointer;
  font-size: 14px !important;
  line-height: 1 !important;
  padding: 8px !important;
  appearance: none !important;
  min-width: 10px !important;
}

.MuiDateCalendar-root *,
.MuiCalendarOrClockPicker-root *,
.MuiFormControl-root,
.MuiTextField-root,
.MuiInputLabel-root,
.MuiInputBase-input::placeholder,
.MuiInputBase-root,
video::cue,
input {
  font-family: "Figtree" !important;
}

.MuiDateCalendar-root *,
.MuiCalendarOrClockPicker-root * {
  font-weight: 600;
  line-height: 1;
}

.meditation-player .add-shadow,
.meditation-player img,
.meditation-player svg {
  filter: drop-shadow(2px 4px 6px #000a);
}
.sl-validationError,
.MuiFormHelperText-root {
  font-family: "Figtree" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.4 !important;
}
.MuiClockNumber-root.Mui-selected,
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  border: 2px solid var(--sl-deep-purple);
  background: white !important;
  color: var(--sl-deep-purple) !important;
  font-weight: 700;
  font-size: 1rem;
}
video::cue {
  font-size: 10px;
  background-color: #4b2b5aaa !important;
  color: white;
  text-wrap: wrap;
  white-space: break-spaces;
}

@media (min-width: 768px) {
  video::cue {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  video::cue {
    font-size: 18px;
  }
}
