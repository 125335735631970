.meditationHeading span {
  letter-spacing: 0.5px;
}
.meditationContainer {
  padding-top: 25px;
}
.disable {
  opacity: 0.4;
  pointer-events: none;
}
.myCreatedMeditation .slick-prev,
.myCreatedMeditation .slick-next {
  top: -25px !important;
}
.main-wrapper {
  border: 0px solid transparent;
  border-radius: 20px 20px 0 0;
  background: var(--bg-gradient);
  top: -15px;
}
.error {
  color: var(--sl-deep-purple);
  font-family: "Figtree";
  margin: 0px;
  margin-top: 7px;
  width: 400px;
  font-size: 12px;
}
.s-container {
  padding-left: 4.8%;
}
.pr-7 {
  padding-right: 4.7%;
}
.d-flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .meditationHeading span:nth-child(2) {
    top: 15px !important;
  }
}

.meditationContainer .createPageLoader .loaderContainer,
.custom-accordian .loaderContainer {
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 107px;
  right: 38px;
  z-index: 9999;
  top: 27%;
}
.meditationContainer .createPageLoader .loader,
.loaderContainer .loader {
  position: absolute;
  left: 60px;
  top: 22px;
  width: 71px;
  height: 71px;
}
.meditationContainer .createPageLoader .MuiAvatar-circular,
.loaderContainer .MuiAvatar-circular {
  position: relative;
  left: 42px;
  top: 28px;
  width: 60px;
  height: 60px;
}

.meditationContainer {
  position: relative;
}
