.gray-wrapper,
.gray-wrapper .MuiAccordion-root {
    background-color: var(--colorVeryGray);
    box-shadow: none;
    border-radius: 10px;
    overflow: hidden;
}

.gray-wrapper .MuiAccordionSummary-content.Mui-expanded {
    margin: 0
}

.gray-wrapper .MuiButtonBase-root {
    min-height: 48px;
}

.quantity-of-mediatation .MuiRadio-root .MuiSvgIcon-root[data-testid="RadioButtonUncheckedIcon"] {
    background: white;
    border-radius: 50%;
    border: 1px solid var(--sl-deep-purple);
    box-sizing: border-box;
}

.quantity-of-mediatation .MuiRadio-root .MuiSvgIcon-root[data-testid="RadioButtonUncheckedIcon"],
.quantity-of-mediatation .MuiRadio-root .MuiSvgIcon-root {
    color: white;
}

.quantity-of-mediatation .MuiRadio-root.Mui-checked .MuiSvgIcon-root[data-testid="RadioButtonCheckedIcon"] {
    color: var(--sl-purple);
}