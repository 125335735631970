.slider-Container .MuiSlider-root {
    height: 5px
}

.slider-Container .MuiSlider-root .MuiSlider-rail,
.slider-Container .MuiSlider-root .MuiSlider-mark
{
    background: var(--sl-purple);
    color: var(--sl-aqua-contrast);
    opacity: 1
}
.slider-Container .MuiSlider-root .MuiSlider-track {
    background: var(--sl-aqua-contrast);
}
.slider-Container .MuiSlider-thumb::before {
    box-shadow: none !important
}

.slider-Container .MuiSlider-root .MuiSlider-mark:nth-child(3),
.slider-Container .MuiSlider-root .MuiSlider-mark:nth-child(4),
.slider-Container .MuiSlider-root .MuiSlider-mark:nth-child(5) {
    height: 14px;
    width: 14px;
    border: 1px solid transparent;
    border-radius: 50%;
}

.slider-Container .MuiSlider-root .MuiSlider-mark:nth-child(4),
.slider-Container .MuiSlider-root .MuiSlider-mark:nth-child(5) {
    left: 99% !important
}

.slider-Container .MuiSlider-root .MuiSlider-mark:nth-child(4) {
    left: 49% !important
}

.slider-Container .MuiSlider-root .MuiSlider-mark:nth-child(3) {
    left: -1% !important
}

.slider-Container .MuiSlider-root .MuiSlider-thumb {
    background-image: url(../../assets/images/sliderNumberIcon.svg);
    background-size: cover;
    width: 40px !important;
    height: 40px !important;
    box-shadow: none !important;
    margin-left:3px;
}

@media screen and (max-width:600px) {
    .slider-Container {
        width: 72% !important;
        position: relative;
        right: 0 !important
    }
}