.sl-heading {
  font-size: 27px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 2px;
}
.sl-headLine {
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 1.2;
  word-spacing: 0;
}

.sl-headingLarge {
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  line-height: 1.2;
}

.sl-subHeading {
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  line-height: 23px;
}

.sl-title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
}

.sl-body {
  font-size: 25px;
  font-weight: 400;
  font-style: normal;
  line-height: 23px;
}

.sl-bodyUnderlined {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
}

.sl-bodyParagraph {
  font-size: 18px;
  font-weight: 200;
  font-style: normal;
  line-height: 23px;
}

.sl-smallBody {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
}
.sl-smallBodyCopy {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
}
.sl-validationError {
  display: block;
  padding-top: 5px;
  padding-left: 15px;
  color: var(--sl-deep-purple);
}
.sl-bodyCopy {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
}
.sl-extraSmallBody {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
}

.sl-smallBodyUnderLined {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
}
.sl-smallFooter {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.sl-extraSmall {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.sl-extraSmallUnderlined {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  text-decoration-line: underline;
}

/* home slides fonts */
.sl-homeParagraphText {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 30px;
}

/* web font sized */
.sl-mainHeadingWeb {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.sl-largeHeader {
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
  line-height: 40px;
}

.sl-subHeadingWeb {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.sl-subhead {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  color: var(--sl-deep-purple);
}
.sl-subheadLight {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
}
.sl-verificationDialog {
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
}
.text-purple {
  color: var(--sl-purple);
}
.text-deepPurple {
  color: var(--sl-deep-purple);
}

.text-lightAqua {
  color: var(--sl-light-aqua);
}

.text-aqua {
  color: var(--sl-aqua);
}

.text-light {
  color: var(--sl-light-text);
}
.text-white {
  color: var(--colorWhite);
}

.text-red {
  color: var(--colorRed);
}

.text-errorDark {
  color: var(--errorDark);
}

.text-errorLightMode {
  color: var(--errorLightMode);
}
.sl-mediumHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20.4px;
  text-align: center;
}
.sl-mediaSubHeading {
  font-size: 19px;
  font-weight: 600;
  color: var(--sl-deep-purple);
  font-style: normal;
  line-height: 23px;
  letter-spacing: -0.3px !important;
}

@media (min-width: 600px) {
  .sl-mediaSubHeading {
    font-size: 27px;
  }
}
.placeholder-light-text::placeholder,
.placeholder-light-text input::placeholder {
  color: var(--sl-light-text);
}
