.list-search-input {
    width: auto !important;
    max-width: 500px !important;
    height: 50px !important;
    background: #FFFFFF !important;
    border: 1px solid #CBC8CD !important;
    border-radius: 30px !important;
    padding-left: 17px !important;
    /* margin-top: 40px !important; */
    margin-bottom: 20px !important;
}
.list-search-input .MuiInputBase-adornedStart>input {
    color: var(--sl-deep-purple) !important;
}
.list-search-input .MuiInputBase-adornedStart {
    height: inherit !important;
}
.list-search-input .MuiInputBase-adornedStart>input::placeholder{
    color: black !important;
    text-transform: capitalize !important;
}