.reminder-input{
    width: 100%;
    background: white;
}
.reminder-input input{
    padding: 13px 14px;
    color: black;
}

.MuiClockPointer-thumb{
    display:none !important;
}
.customClock{
    position: fixed;
    background: white;
    z-index: 999;
    top: 56.5%;
    left: 38%;
}