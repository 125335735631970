/* .boxClass {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #4B2B5A;
    margin-top: 10px;
    padding: 30px;
} */
.MuiSwitch-thumb{
    width: 27px !important;
    height: 27px !important;
}
.MuiSwitch-track {
    border-radius: 38px !important;
    background-color: #787880 !important;
    opacity: 0.38 !important;
}
.MuiButtonBase-root.MuiSwitch-switchBase {
    padding: 14px !important;
}
.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
    color: white !important;
}
.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #34C759 !important;
    opacity: 1 !important;
}
