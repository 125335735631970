.upload-btn {
    font-family: 'Figtree' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 22px !important;
    text-transform: none !important;
    color: var(--colorWhite) !important;
    text-decoration-line: underline !important;
    height: 0;
}
.upload-btn:hover,
.upload-btn:focus,
.upload-btn:active,
.upload-btn:is {
    background-color: var(--colorWhite) !important;
}
.info-btn {
    width: 205px !important;
    height: 50px !important;
    border: 1px solid #A3AFCE !important;
    border-radius: 8px !important;
    box-sizing: border-box !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 22px 110px !important;
    gap: 10px !important;
    font-family: 'Figtree' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 20px !important;
    border: 1px solid #A3AFCE !important;
    border-radius: 8px !important;
}
.skip-btn {
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 22px 110px !important;
    gap: 10px !important;
    width: 427px !important;
    height: 64px !important;
    background: #FFFFFF !important;
    border-radius: 8px !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 20px !important;
    color: #697382 !important;
}
.photo-size {
    font-family: 'Figtree' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 22px !important;
    text-align: center !important;
    margin-top: 10px !important;
    margin-Bottom: 15px !important;
    color: var(--colorWhite) !important;
}

.remove-photo{
    font-family: 'Figtree' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 22px !important;
    text-align: center !important;
    margin-bottom: 15px !important;
    margin-top: 10px !important;
    color: var(--colorWhite) !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}