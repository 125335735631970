.backdrop-blur {
    backdrop-filter: blur(3px) !important;
    background-color: rgba(0,0,30,0.4) !important;
    z-index: 999999 !important;
}

.rotate {
    /* animation: circular-rotate 1.4s linear infinite; */
}

@keyframes circular-rotate {
    0% {
      transform: rotate(0deg);
      /* Fix IE11 wobbly */
      transform-origin: 50% 50%;
    }
    100% {
      transform: rotate(360deg);
    }
}