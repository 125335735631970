.home-screen-height-controller {
  min-height: 73vh;
}
.media-section-summary .MuiAccordionSummary-content {
  justify-content: space-between;
}
.main-wrapper {
  border: 0px solid transparent;
  border-radius: 20px 20px 0 0;
  /* background-color: var(--bg-gradient); */
  top: -15px;
  z-index: 5;
  /* background: var(--bg-gradient); */
}
.input-text-style .MuiInputBase-colorPrimary {
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid #cbc8cd;
  background-color: var(--colorWhite);
}
.homeMeditationList .slick-next,
.homeMeditationList .slick-prev {
  top: -35px !important;
}
.s-container {
  padding-left: 4.8%;
}
.input-text-style > label {
  font-family: "Figtree";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
}
.buildMeditationBtn {
  background: #762b90 !important;
  color: white !important;
  width: 200px;
  height: 50px;
  border-radius: 25px !important;
  text-transform: capitalize !important;
  margin-top: 30px !important;
}
.pr-7 {
  padding-right: 4.7%;
}
.homeMeditationLogo {
  position: absolute;
  top: -36px;
  left: 48%;
  z-index: 5;
}
.error {
  font-family: "Figtree";
}

@media screen and (max-width: 480px) {
  .homeMeditationLogo {
    left: 41%;
  }
  .myListHomeScreen .meditationContainer {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .homeMeditationLogo {
    left: 44.5%;
  }
}

@media screen and (max-width: 1024px) {
  .homeMeditationLogo {
    left: 47%;
  }
}

@media screen and (max-width: 480px) {
  .homeMeditationLogo {
    left: 41%;
  }
}
@media screen and (max-width: 600px) {
  .meditationHeading span:nth-child(2) {
    top: 15px !important;
  }
}

/* fot screen height */
@media screen and (max-width: 2000px) {
  .home-screen-height-controller {
    min-height: 92vh;
  }
}

@media screen and (max-width: 1950px) {
  .react-joyride__spotlight {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1750px) {
  .react-joyride__spotlight {
    margin-top: 0px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1700px) {
  .react-joyride__spotlight {
    margin-top: 1px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1550px) {
  .react-joyride__spotlight {
    margin-top: 0px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1450px) {
  .react-joyride__spotlight {
    margin-top: 0px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1420px) {
  .react-joyride__spotlight {
    margin-top: 1px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1370px) {
  .react-joyride__spotlight {
    margin-top: 0px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1280px) {
  .react-joyride__spotlight {
    margin-top: 0px;
    margin-left: -1px;
  }
}
