#hero-section .main-div {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  margin-top: 0px;
  height: 99%;
  align-items: flex-end;
  position: relative;
  bottom: 50px;
}

/* #hero-section .paper-text-change-screen {
  width: 150px;
  height: 44px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: 15px;
} */
#hero-section .paper-text-change-screen {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.btnDailyMeditation {
  background-color: var(--sl-purple) !important;
  color: white !important;
  border-radius: 26px !important;
  padding: 5px 7px !important;
  text-align: left;
  justify-content: flex-start !important;
  font-weight: 600;
  font-family: "Figtree";
  text-transform: capitalize !important;
  font-size: 15px !important;
  letter-spacing: 0.5px !important;
  padding-right: 35px !important;
}
.backgroundVideoContainer {
  position: absolute;
  bottom: 0px;
  width: -webkit-fill-available;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  flex-direction: column;
  padding-top: 35px;
  padding-bottom: 60px;
  display: none;
  transition: height 0ms 400ms, opacity 400ms 0ms;
  z-index: 3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow-x: auto !important;
}
.backgroundScene {
  display: flex;
  gap: 20px;
  z-index: 1;
  padding-top: 25px;
  min-height: 200px;
  overflow-x: auto;
  padding-left: 20px;
  padding-right: 10px;
}
.sceneCard {
  position: relative;
}
.sceneCardImage {
  height: 230px;
  width: 150px;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}
.selectedCard.sceneCard::after {
  content: "";
  width: 150px;
  height: 230px;
  border: 3px solid var(--sl-purple) !important;
  display: block;
  position: absolute;
  top: -3px;
  left: -3px;
  border-radius: 15px;
}
.backgroundVideoContainer::after {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px;
  border-radius: 10px;
}
.arrowDownWhite {
  position: absolute;
  right: 80px;
  top: 30px;
  z-index: 1;
  cursor: pointer;
}
.d-block {
  display: block;
}
.sceneSelected {
  position: absolute;
  left: 40%;
  top: 41%;
}
#myVideo {
  position: absolute;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
  user-select: none;
  pointer-events: none;
  z-index: 0;
}
#cover-spin {
  width: 15px;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  display: block;
  /* position: absolute; */
  left: 73px;
  top: 40%;
  width: 22px;
  height: 22px;
  border-style: solid;
  border-color: white;
  border-top-color: transparent;
  border-width: 3px;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}
/* @media screen and (min-width: 1500px) {
  .backgroundVideoContainer{
    width: 98%;
  }
} */
@media screen and (max-width: 768px) {
  #hero-section .paper-text-change-screen {
    width: 60px;
    height: 60px;
    top: 30px;
  }
}
@media screen and (max-width: 900px) {
  #hero-section .paper-text-change-screen {
    width: 60px;
    height: 60px;
    /* top: 30px; */
  }
}
@media screen and (max-width: 700px) {
  .sceneCardImage {
    height: 170px;
    width: 115px;
  }
  .backgroundVideoContainer {
    padding-top: 25px;
    padding-bottom: 40px;
    /* width: 73.5%; */
  }
  .homeMeditationLogo {
    left: 45%;
  }
  #hero-section .paper-text-change-screen {
    top: 38px;
    width: 60px;
    height: 60px;
  }
  .selectedCard.sceneCard::after {
    width: 115px;
    height: 170px;
    top: -2px;
    left: -2px;
  }
  .sceneSelected {
    position: absolute;
    left: 37%;
    top: 30%;
    width: 35px;
  }
}
@media screen and (max-width: 430px) {
  #hero-section .paper-text-change-screen {
    width: 50px;
    height: 50px;
  }

  .sceneCardImage {
    height: 150px;
    width: 100px;
  }

  .backgroundVideoContainer {
    padding-top: 15px;
    padding-bottom: 15px;
    /* width: 73.5%; */
  }

  .homeMeditationLogo {
    left: 43%;
  }

  .arrowDownWhite {
    right: 42px;
    top: 25px;
  }

  .selectedCard.sceneCard::after {
    width: 100px;
    height: 150px;
    top: -2px;
    left: -2px;
  }

  .sceneSelected {
    position: absolute;
    left: 37%;
    top: 30%;
    width: 35px;
  }

  .btnDailyMeditation {
    padding: 5px 7px !important;
    width: 190px !important;
    font-size: 14px !important;
  }

  .btnDailyMeditation img {
    width: 35px;
  }
}

.backgroundScene::-webkit-scrollbar {
  display: none;
}
