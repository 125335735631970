.listItem{
  padding:0px !important;
}
.listItem a:first-child li{
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.listItem li.item_active{
  border-radius: 0px;
}
.listItem a:first-child li{
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.listItem a:last-child li.item_active {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.listItem a:last-child{
  border-bottom: 0px;
}
.listItem a{
  display: block;
  border-bottom: 1px solid #e0e0e0;
}
.listItem a:last-child li .white-icon,.listItem a:last-child li.item_active .purple-icon{
  display:none
 }
.listItem a:last-child li.item_active .white-icon{
 display:block;
 filter: none !important;
}
.listItem  a[title="Reset Tutorials"] svg[data-testid="ArrowForwardIosIcon"]{
  display:none
}
/* .profileSidebar .listItem a:nth-child(4) li svg{
  margin-left: -6px;
  position: relative;
  left: 3px;
}

*/