
  #about-us .image-for-about {
    width:420px;
    height: 301px;

}

 @media only screen and (max-width:768px) {
    #about-us .image-for-about {
         width:450px;
         height: auto;
         padding: 0px 16px;
         

     }
 }


@media only screen and (max-width:480px) {
     #about-us .image-for-about {
         width:340px;
         height: auto;
         padding: 0px 16px;


     }
     }
