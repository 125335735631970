.subscriptionBox{
    color: #4B2B5A;
    background: var(--colorWhite);
    font-size: 15px;
    font-weight: 400;
    border-radius: 10px;
    line-height: 43px;
    /* padding:30px; */
    margin-top: 30px;
}
.heading3{
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 22px;
    word-spacing: 0;
    margin-top:0px;
    margin-bottom:5px;
}
.heading3 span{
    font-size: 12px;
}
.subscriptionLabel{
    font-size: 12px;
    font-weight: 400;
    padding-top: 5px;
    font-family: "Figtree";
    margin: 0px;
    line-height: normal;
    margin-top: 20px;
    color: #4B2B5A;
}
.subscriptionValue{
    font-size: 17px;
    color: var(--sl-aqua);
    line-height: normal;
    margin: 0px;
    font-family: 'Figtree';
    text-transform:capitalize;
}
.deepPurple{
    color: #4B2B5A;
}
.color-red{
    color:red;
}