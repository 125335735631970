.meditation-summary-btn-text>button .MuiTypography-root {
    position: absolute;
    left: 43px;
    color: var(--sl-deep-purple);
    letter-spacing: 0 !important;
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}

.meditation-summary-button>button>p {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0 !important;
}

.meditation-summary-main-container .MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.meditation-summary-main-container .MuiInputBase-inputMultiline{
    color: #7B767C !important;
}
/* .MuiPaper-rounded{
    max-width: 700px !important;
} */