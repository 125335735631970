.backdrop-meditation-blur {
  background: rgb(75 43 90 / 91%) !important;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(12.5px) !important;
  z-index: 6 !important;
}

.loader {
  width: 138px;
  height: 138px;
  border: 4px solid #67a3b2;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  left: 46px;
  top: -9px;
}
.back-btn-loader {
  color: white;
  font-family: "Figtree";
  font-size: 15px;
  position: absolute;
  top: 70px;
  left: 90px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor:pointer;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
