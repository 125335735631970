.search-input {
    width: auto !important;
    max-width: 500px !important;
    height: 50px !important;
    background: #FFFFFF !important;
    border: 1px solid #CBC8CD !important;
    border-radius: 30px !important;
    padding-left: 17px !important;
    /* margin-top: 40px !important; */
    margin-bottom: 44px !important;
}
.search-input .MuiInputBase-adornedStart>input {
    color: var(--sl-deep-purple) !important;
}
.search-input .MuiInputBase-adornedStart {
    height: inherit !important;
}
.search-input .MuiInputBase-adornedStart>input::placeholder{
    color: black !important;
    text-transform: capitalize !important;
}
.MuiToggleButton-root.Mui-selected .MuiCard-root .sl-extraSmallBody,
.MuiToggleButton-root.Mui-selected .MuiCard-root .sl-extraSmallBody .number-style-change{
    color: white !important;
}
.MuiToggleButton-root.Mui-selected .MuiCard-root .media-image {
background-image: none !important;
}
.toggle-btn .MuiToggleButton-root.Mui-selected .MuiCard-root .sl-extraSmallBody,
.toggle-btn .MuiToggleButton-root.Mui-selected .MuiCard-root .sl-extraSmallBody .number-style-change{
    color: white !important;
}
.toggle-btn .MuiToggleButton-root {
    min-width: 40px;
    color: var(--sl-deep-purple) !important;
}
.content2::before {
    content: "\a";
    white-space: pre;
}

@media screen and (min-width: 1280px){
.react-joyride__spotlight {
    margin-top: 0px;
    margin-left: 0px;
}
}