/* sl-button properties will be applied to complete button */
.sl-button {
    font-weight: 500 !important;
    height: 50px !important;
    border-radius: 25px !important;
    /* To remove upper case text */
    text-transform: unset !important;
}
.sl-button>p{
    font-size: 14px ;
    line-height: 18px !important;
    font-family: 'Figtree' !important;
    font-weight: 600 ;
    letter-spacing: -0.3px !important;
}

.gray {
    color: var(--colorDarkGray) !important;
    background-color: var(--colorLightGray) !important;
}

.bordered {
    background: var(--colorWhite) !important;
    border: 1px solid var(--colorBlue) !important;
}

.blue {
    color: var(--colorWhite) !important;
    background-color: var(--colorLightBlue) !important;
}

.purple {
    color: var(--colorWhite) !important;
    background-color: var(--sl-purple) !important;    
} 

.purple .Mui-disabled {
    color: var(--colorWhite) !important;
    opacity: .7 !important;
}
.white{
    color: var(--sl-purple) !important;
    background-color: var(--colorWhite) !important;
}

.aqua {
    color: var(--colorWhite) !important;
    /* background: var(--bg-gradient) !important; */
    background-color: var(--sl-aqua) !important;
}


@media (max-width: 768px) {
    .sl-button {
        font-size: medium;
    }
}

@media (max-width: 600px) {
    .sl-button {
        font-size: x-small;
    }
}
