.disable-meditations {
  opacity: 0.4;
  pointer-events: none;
}

.myCreatedMeditation .slick-prev {
  right: 75px !important;
  top: -35px;
  z-index: 1;
  background: transparent;
  position: absolute;
  width: 30px !important;
  height: 30px !important;
}

.myCreatedMeditation .slick-next {
  right: 50px !important;
  top: -35px;
  width: 30px !important;
  height: 30px !important;
}
.meditationContainer .loaderContainer,
.custom-accordian .loaderContainer {
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 107px;
  right: 50px;
  z-index: 9999;
  top: 38%;
}
.meditationContainer .loader,
.loaderContainer .loader {
  position: absolute;
  left: 60px;
  top: 22px;
  width: 71px;
  height: 71px;
}
.meditationContainer .MuiAvatar-circular,
.loaderContainer .MuiAvatar-circular {
  position: relative;
  left: 42px;
  top: 28px;
  width: 60px;
  height: 60px;
}

.meditationContainer {
  position: relative;
}
@media screen and (max-width: 2560px) {
  .myCreatedMeditation .slick-next {
    right: 75px !important;
  }

  .myCreatedMeditation .slick-prev {
    right: 100px !important;
  }
}

@media screen and (max-width: 1536px) {
  .myCreatedMeditation .slick-next {
    right: 56px !important;
  }

  .myCreatedMeditation .slick-prev {
    right: 75px !important;
  }
}

@media screen and (max-width: 1440px) {
  .myCreatedMeditation .slick-next {
    right: 53px !important;
  }

  .myCreatedMeditation .slick-prev {
    right: 72px !important;
  }
}

@media screen and (max-width: 1396px) {
  .myCreatedMeditation .slick-next {
    right: 53px !important;
  }

  .myCreatedMeditation .slick-prev {
    right: 72px !important;
  }
}

@media screen and (max-width: 1228px) {
  .myCreatedMeditation .slick-next {
    right: 45px !important;
  }

  .myCreatedMeditation .slick-prev {
    right: 63px !important;
  }
}

@media screen and (max-width: 1024px) {
  .myCreatedMeditation .slick-next {
        right: 38px !important;
        }
  .myCreatedMeditation .slick-prev {
    right: 58px !important;
  }
}

@media screen and (max-width: 768px) {
  .myCreatedMeditation .slick-prev {
    right: 44px !important;
    top: -20px !important;
  }

  .myCreatedMeditation .slick-next {
    right: 24px !important;
    top: -20px !important;
  }
}

@media screen and (max-width: 500px) {
  .myCreatedMeditation .slick-prev {
    right: 30px !important;
    top: -20px !important;
  }

  .myCreatedMeditation .slick-next {
    right: 10px !important;
    top: -20px !important;
  }
}

@media screen and (max-width: 425px) {
  .myCreatedMeditation .slick-prev {
    right: 30px !important;
    top: -24px !important;
  }

  .myCreatedMeditation .slick-next {
    right: 8px !important;
    top: -24px !important;
  }

  .meditationContainer {
    margin-top: 20px !important;
  }
}