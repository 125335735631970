.custom-accordian {
  box-shadow: none !important;
  border-radius: 10px !important;
  background: transparent;
}
.custom-accordian .MuiAccordionSummary-root {
  min-height: 100px !important;
  flex-direction: row-reverse;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 0px solid transparent;
  border-radius: 10px;
  padding-left: 24px;
  position: relative;
  /* padding-top: 35px; */
}
.custom-accordian .MuiAccordionSummary-root.whiteIcon::after {
  backdrop-filter: blur(15px);
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px;
  border-radius: 10px;
}
.custom-accordian .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
  fill: #4b2b5a;
}
.custom-accordian .Mui-expanded {
  border-radius: 10px 10px 0px 0px;
}
.whiteIcon svg {
  fill: #ffffff !important;
}
.MuiAccordionSummary-content {
  padding-left: 5px;
  align-items: center;
  z-index: 1;
}
.MuiAccordionSummary-expandIconWrapper {
  z-index: 1;
}
.meditationSlider .slick-slide {
  margin-right: 15px;
}
.meditationSlider .slick-track {
  padding: 8px 0;
  padding-top: 0px;
  margin-left: 0px;
}
.meditationSlider .sl-button .MuiButton-startIcon {
  margin-right: 3px;
}
.meditationSlider .sl-button .MuiButton-startIcon svg {
  font-size: 16px !important;
}
.custom-accordian .MuiAccordion-region {
  padding-right: 0px;
}
.slick-slide {
  width: 150px;
}

.audio-icon {
  position: absolute;
  right: 20px;
}
.custom-accordian .MuiAccordionDetails-root {
  padding-right: 0px;
  padding-left: 0px;
}
.button-style {
  background: none;
  border: none;
  cursor: pointer;
}
.notselectedAccordian .MuiAccordionSummary-gutters {
  background: white;
  /* border: 10px 10px 0px 0px; */
}
.notselectedAccordian .background-change-text {
  background: white;
}
.notselectedAccordian .custom-accordian .Mui-expanded {
  border-radius: 10px !important;
}

.custom-skelton {
  width: 150px;
  height: 230px !important;
  border: 0px solid transparent;
  border-radius: 10px;
  transform: none !important;
}
.arrow-container {
  width: 110px;
  text-align: right;
}
.slider-container {
  position: relative;
}

.slick-prev {
  right: 35px !important;
  top: -35px !important;
  z-index: 99999;
  background: transparent;
  position: absolute;
  left: auto !important;
}
.slick-next {
  right: 10px !important;
  top: -35px !important;
}
.slick-prev {
  background-image: url(../../assets/images/arrowLeft.png) !important;
  background-size: cover !important;
  width: 30px !important;
  height: 30px !important;
}
.slick-next {
  background-image: url(../../assets/images/arrowRight.png) !important;
  background-size: cover !important;
  width: 30px !important;
  height: 30px !important;
}
.slick-prev:before,
.slick-next:before {
  content: "" !important;
  font-size: 40px !important;
  opacity: 1 !important;
  color: #403c3c !important;
}
.slick-next.slick-disabled,
.slick-prev.slick-disabled {
  opacity: 0.3;
}

.meditation-cards {
  padding-left: 25px;
  background: transparent;
}

@media screen and (max-width: 1000px) {
  .slick-prev {
    top: -22px !important;
  }
  .slick-next {
    top: -22px !important;
  }
}
@media screen and (max-width: 600px) {
  .arrow-container {
    width: 242px;
    text-align: right;
    position: absolute;
    right: -13px;
    /* top: 10px; */
    bottom: -10px;
  }
  .tagContainer {
    margin-bottom: 20px !important;
  }
  .slick-prev {
    top: -20px !important;
  }
  .slick-next {
    top: -20px !important;
  }
}
@media screen and (max-width: 480px) {
  .meditation-cards {
    padding-left: 0px;
  }
}

/* changes  */
.selectedAccordian .MuiButtonBase-root {
  background: none !important;
}

.meditationContainer .slider-container .loaderContainer,
.custom-accordian .loaderContainer {
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 107px;
  right: 38px;
  z-index: 9999;
  top: 18% !important;
}
.meditationContainer .slider-container .loader,
.loaderContainer .loader {
  position: absolute;
  left: 60px;
  top: 22px;
  width: 71px;
  height: 71px;
}
.meditationContainer .slider-container .MuiAvatar-circular,
.loaderContainer .MuiAvatar-circular {
  position: relative;
  left: 42px;
  top: 28px;
  width: 60px;
  height: 60px;
}

.meditationContainer .slider-container {
  position: relative;
}
.loaderContainer {
  bottom: 120px;
}
