.upload-btn {
  font-family: "Figtree";
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  text-transform: none !important;
  color: var(--sl-purple) !important;
  text-decoration-line: underline !important;
  height: 0 !important;
}
.upload-btn:hover {
  background-color: transparent !important;
}

.upload-btn:focus,
.upload-btn:active,
.upload-btn:is {
  background-color: var(--sl-purple) !important;
}

.Mui-disabled:hover,
.Mui-disabled > input:hover {
  background-color: #d2cecb !important;
  border-radius: 10px !important;
}

.infoDialog-box .photo-size {
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 22px !important;
  text-align: center !important;
  margin-top: 10px !important;
  color: var(--sl-purple) !important;
}

.infoDialog-box .remove-photo {
  font-family: "Figtree" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 22px !important;
  text-align: center !important;
  margin-top: 10px !important;
  color: var(--sl-purple) !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.date-picker .MuiInputBase-root {
  background-color: var(--colorWhite) !important;
  border-radius: 10px !important;
  border: 1px solid var(--colorLightGray) !important;
  color: var(--sl-deep-purple) !important;
  font-size: 17px;
  font-weight: 500;
  margin-top: 15px !important;
}

.date-picker .MuiInputLabel-standard,
.date-picker .MuiFormLabel-root,
.date-picker .MuiFormLabel-root.Mui-focused {
  color: var(--sl-deep-purple) !important;
  left: 4px !important;
  margin-top: 17px !important;
  font-family: "Figtree" !important;
}

.date-picker .Mui-error {
  color: var(--sl-deep-purple) !important;
}

.form-control .MuiInputBase-root {
  color: var(--sl-deep-purple) !important;
  border-radius: 10px !important;
  border: 0px solid var(--colorLightGray) !important;
}

.form-control {
  background-color: var(--colorWhite) !important;
  border-radius: 10px !important;
  margin-top: 15px !important;
}

.form-control .MuiInputLabel-standard {
  padding-left: 15px !important;
  margin-top: 15px !important;
}

.set-placeholder {
  width: 100% !important;
}

.confirmation-dialog-box {
  justify-content: center !important;
  align-items: center !important;
  padding: 17px !important;
  gap: 10px !important;
  background: var(--bg-gradient) !important;
}

.forgot-password-email-input .Mui-error {
  color: var(--sl-purple) !important;
  margin-left: -2px !important;
}

.infoDialog-box {
  padding: 5% !important;
  background: var(--bg-gradient) !important;
}

.profile-details-popup .forgot-password-email-input .MuiFilledInput-input {
  padding-top: 15px;
}
.profile-details-popup .MuiInputBase-inputAdornedEnd {
  padding-left: 15.7px;
  padding-top: 18.5px;
}
.profile-details-popup .MuiInputLabel-formControl {
  top: -3px;
}

.phone-number-input-field .MuiInputLabel-shrink {
  top: -5px;
}
.select-gender {
  position: absolute;
  top: 1.5px;
}
/* .select-gender:focus {
      position: absolute;
      top: -2.5px !important;
    } */
.profile-details-popup .css-1wkg3nu-SingleValue {
  margin-top: -4px;
}

.state-selection-list .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-radius: 10px;
  color: black !important;
  font-family: Figtree;
}

.state-selection-list .MuiInputLabel-shrink {
  top: 14.5px;
  font-family: Figtree !important;
  color: #514c54 !important;
}
.state-selection-list .Mui-focused {
  font-family: Figtree;
  color: #514c54 !important;
}
.state-selection-list .MuiInputBase-input {
  color: var(--sl-deep-purple);
  top: 5px;
  position: relative;
  font-family: Figtree !important;
  /* height: 20px !important; */
}
.state-selection-list .MuiOutlinedInput-root {
  height: 50px;
  font-family: Figtree !important;
  color: var(--sl-deep-purple);
  font-size: 17px;
  font-weight: 500;
}
.state-selection-list .MuiModal-root {
  width: 383px !important;
  left: 663px !important;
}

.state-selection-list .MuiPaper-elevation {
  width: 383px !important;
  left: 663px !important;
}
.disable {
  pointer-events: none;
  opacity: 0.4;
}
/*
#demo-multiple-name::-webkit-scrollbar {
  width: 4px !important;
}
*/
.dateOfBirthColor .MuiInputLabel-formControl {
  color: var(--sl-light-text) !important;
}

.auth-wrapper .MuiTypography-validationError,
.auth-wrapper .MuiFormHelperText-root {
  margin: 0;
  padding: 5px 0 0 0;
  color: var(--errorDark);
  font-weight: normal !important;
}
