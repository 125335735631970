
#playBack .button-styles{
    background: var(--colorWhite);
    border: 1px solid var(--colorBlue);
    border-radius: 8px;
}

#playBack .video-player{
    margin-top: 2%;
}


@media (max-width: 768px) {
    #playBack .button-styles{
        font-size: medium;
    }
}

@media (max-width: 600px) {
    #playBack .button-styles{
        font-size: x-small;
    }
}


