.create-Achievement-popup .MuiTypography-heading {
    letter-spacing: -0.3px;
}

.create-Achievement-dailogbox .MuiDialog-paperWidthSm {
    max-width: 100% !important;
}

@media screen and (min-width: 1024px) {
    .create-Achievement-dailogbox .MuiDialog-paperWidthSm {
        width: 1045px !important;
    }  
}
@media screen and (max-width: 1024) {
    .create-Achievement-dailogbox .MuiDialog-paperWidthSm {
        max-width: 999px !important;
    }  
}