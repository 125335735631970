.redirection-link {
  cursor: pointer;
  text-decoration: none !important;
  border-bottom: 1px solid currentColor;
}

.redirection-link:hover {
  text-decoration: none;
}

.forgot-password {
  text-align: right !important;
}

.m-auto {
  margin: 0 auto;
}

.mainDialog-box {
  justify-content: left;
  align-items: left;
  padding: 25px 100px 17px 17px;
  gap: 20px;
  background: var(--bg-gradient);
}
.gridItemContainer {
  min-height: 100vh;
  color: var(--colorWhite) !important;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  min-height: 100vh;
  position: absolute;
  background-position: center;
}
.gridItemContainer .background-color-change {
  color: var(--sl-deep-purple) !important;
}
.auth-container {
  .MuiFormHelperText-root {
    color: var(--errorDark) !important;
  }

  .auth-wrapper {
    position: relative;
    margin-bottom: 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .redirection-link {
      color: var(--colorWhite) !important;
    }

    .emailPasswordStyles {
      .MuiInputLabel-formControl {
        font-family: Figtree !important;
      }

      .emailPasswordStyles .sl-button > p {
        font-size: 15px !important;
      }
    }
  }
}
