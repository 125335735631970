.custom-accordian-meditations {
  box-shadow: none !important;
  border-radius: 10px !important;
  background: transparent !important;
}
.custom-accordian-meditations .MuiAccordionSummary-root {
  min-height: 100px !important;
  flex-direction: row-reverse;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 0px solid transparent;
  border-radius: 10px;
  position: relative;
  padding-right: 0px;
  padding-left: 0px;
}
.custom-accordian-meditations .MuiAccordionSummary-root.whiteIcon::after {
  backdrop-filter: blur(15px);
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px;
  border-radius: 10px;
}
.custom-accordian-meditations .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
  fill: #4b2b5a;
}
.whiteIcon svg {
  fill: #ffffff !important;
}
.dialog-box-meditation {
  padding: 25px;
}
.changeAudioBlock {
  display: flex;
  padding: 9px 20px;
  align-items: center;
  width: 96.6%;
}
.custom-accordian-meditations .Mui-expanded {
  border-radius: 10px 10px 0px 0px;
}
.custom-accordian-meditations .MuiAccordionSummary-content {
  z-index: 1;
  margin: 0px !important;
  flex-direction: column;
  padding-left: 0px;
}
.accordianHeader .mediaContainer {
  height: 100px;
  /* position: relative; */
  /* top: -10px; */
  margin-right: 20px;
  /* object-fit: cover; */
  width: 80px;
  border-top-left-radius: 10px;
  background-color: #ccc;
}
.mediaContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 0px solid transparent;
  border-top-left-radius: 10px;
}
.mediaContainer img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  line-height: 100px;
  background-color: #808080;
  color: currentColor;
  text-align: center;
  border-top-left-radius: 10px;
  display: block;
  width: 80px;
  height: 100px;
  overflow: hidden;
}
.meditation-slider-container .loaderContainer {
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 107px;
  right: 40px;
  z-index: 9999;
  top: 15px;
}
.meditation-slider-container .loader {
  position: absolute;
  left: 60px;
  top: 57px;
  width: 71px;
  height: 71px;
}
.titleContainer{
  width:75%
}
.meditation-slider-container .MuiAvatar-circular {
  position: relative;
  left: 42px;
  top: 63px;
  width: 60px;
  height: 60px;
}
.meditation-slider-container {
  position: relative;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.MuiAccordionSummary-expandIconWrapper {
  z-index: 1;
}
.meditationSlider .slick-slide {
  margin-right: 15px;
}
.meditationSlider .slick-track {
  padding: 8px 0;
  padding-top: 0px;
  margin-left: 0px;
}
.meditationSlider .sl-button .MuiButton-startIcon {
  margin-right: 3px;
}
.meditationSlider .sl-button .MuiButton-startIcon svg {
  font-size: 16px !important;
}
.custom-accordian-meditations .MuiAccordion-region {
  padding-right: 0px;
}
.slick-slide {
  width: 150px;
}
.card-disabled {
  pointer-events: none;
  cursor: unset;
}
.audio-icon {
  position: absolute;
  right: 20px;
}
.custom-accordian-meditations .MuiAccordionDetails-root {
  padding-right: 0px;
  padding-left: 0px;
}
.button-style {
  background: none;
  border: none;
  cursor: pointer;
}

.custom-skelton {
  width: 150px;
  height: 230px !important;
  border: 0px solid transparent;
  border-radius: 10px;
  transform: none !important;
}
.arrow-container {
  width: 110px;
  text-align: right;
}
.meditation-slider-container {
  position: relative;
  padding-left: 25px;
}
.accordianHeader {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
}
.slick-prev {
  right: 35px !important;
  top: -35px !important;
  z-index: 99999;
  background: transparent;
  position: absolute;
  left: auto !important;
}
.slick-next {
  right: 10px !important;
  top: -35px !important;
}
.slick-prev {
  background-image: url(../../assets/images/arrowLeft.png) !important;
  background-size: cover !important;
  width: 30px !important;
  height: 30px !important;
}
.slick-next {
  background-image: url(../../assets/images/arrowRight.png) !important;
  background-size: cover !important;
  width: 30px !important;
  height: 30px !important;
}
.slick-prev:before,
.slick-next:before {
  content: "" !important;
  font-size: 40px !important;
  opacity: 1 !important;
  color: #403c3c !important;
}
.slick-next.slick-disabled,
.slick-prev.slick-disabled {
  opacity: 0.3;
}
.loader {
  width: 138px;
  height: 138px;
  border: 4px solid #67a3b2;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  left: 46px;
  top: -9px;
}
.shareIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  right: 120px;

}
@media screen and (max-width: 1000px) {
  .slick-prev {
    top: -22px !important;
  }
  .slick-next {
    top: -22px !important;
  }
  .titleContainer{
    width:65%
  }
}
@media screen and (max-width: 600px) {
  .titleContainer{
    width:60%
  }
  .arrow-container {
    width: 242px;
    text-align: right;
    position: absolute;
    right: -13px;
    bottom: -10px;
  }
  .shareIcon {
    right: auto;
    left: 98px;
    top: 75px;
  }
  .tagContainer {
    margin-bottom: 20px !important;
  }
  .slick-prev {
    top: -20px !important;
  }

  .slick-next {
    top: -20px !important;
  }
}
