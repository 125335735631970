.barChart .recharts-cartesian-axis line{
        display: none !important;
}
.weeklyMeditationStats .barChart text{
    font-family: "Figtree";
    color:"#492B5B";
    font-size: 12px;
}
.barChart text{
    fill:#492B5B
}
.recharts-reference-line{
    transform-origin: 50% 35%;
    transform: scale(0.936);
}
.goalHeading{
    font-size:17px;
    font-family: "Figtree";
    font-weight: 600;
    color:#492B5B;
    text-align: center;
    margin-bottom: 25px;
}