/*****Color Styling and Font Family*****/

:root{
  --colorWhite: #FFFFFF;
  /* --colorLightGray: #F8F8F8; */
  --colorMeduimLightGray: #ECF0F3;
  --colorGray: #DBDEE4;
  --colorDarkGray: #697382;
  --colorDarkGrayContrast: #6F6971;
  --colorVeryGray: #E7E7E7;
  --colorLightGray: #CBC8CD;
  --colorLightBlue: #A3AFCE;
  --colorBlue: #4082E6;
  --colorBlack: #000000;
  --colorRed: red;
  --errorLightMode: #ED0D13;
  --errorDark: #FFB2B2;
  --colorGreen: #7ECC59;
  --PrimaryFontFamily: 'Figtree', 'Open Sans', sans-serif;
  /* colors */
  --sl-purple: #762B90;
  --sl-deep-purple: #4B2B5A;
  --sl-aqua: #3E7E96;
  --sl-aqua-contrast: #046A8F;
  --sl-light-aqua: #B7D2D9;
  --sl-light-text: #514C54;
  --bg-gradient: linear-gradient(180deg, #F0E3DC 40%, #EFF2F3 65.85%)
}

@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap');

body,html {
  padding: 0;
  margin: 0;
  height: 100%;
  min-width: 300px;
}
#root {
  background: var(--bg-gradient);
}
/* width */
/* ::-webkit-scrollbar {
  width: 4px;
} */
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--bg-gradient);
  border-radius: 10px;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: var(--bg-gradient);
} */

.css-18sg6k4-MuiPaper-root-MuiDrawer-paper{
  background: var(--bg-gradient) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
}

/* * {
  font-family: var(--PrimaryFontFamily) !important;
} */

.meditationSlider .slick-slide {
  margin-right: 15px;
}
.meditationSlider .slick-track {
  padding: 8px 0;
  padding-top: 0px;
  margin-left: 0px;
}
.meditationSlider .sl-button .MuiButton-startIcon {
  margin-right: 3px;
}
.meditationSlider .sl-button .MuiButton-startIcon svg {
  font-size: 16px !important;
}