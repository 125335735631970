.quotes-text {
  font-family: Figtree;
  /* padding: 30px !important; */
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: auto;
  box-sizing: border-box;
}
.quotes-text:hover {
  background-color: white;
}
.selectAbleText {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
