
.button-style{
    background: none;
    border: none;
    cursor: pointer;
}

.disable-meditations{
    opacity:0.4;
    pointer-events: none;
}
.myCreatedMeditation .slick-prev {

    z-index: 1;
    background: transparent;
    position: absolute;
    width:30px !important;
    height:30px !important;
}
.myCreatedMeditation .slick-next {
 
    width:30px !important;
    height:30px !important;
}
.meditationContainer .loaderContainer{
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 107px;
    right: 40px;
    z-index: 9999;
    top: 15px;
}
.meditationContainer .loader{
    position: absolute;
    left: 60px;
    top: 57px;
    width: 71px;
    height: 71px;
    border: 4px solid #67a3b2;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}


.meditationContainer .MuiAvatar-circular{
    position: relative;
    left: 42px;
    top: 63px;
    width:60px;
    height:60px;
}
.meditationContainer{
    position: relative;
}

.meditationSlider .slick-slide {
    margin-right: 15px;
}
.meditationSlider .slick-track {
    padding: 8px 0;
    padding-top: 0px;
    margin-left: 0px;
}
.meditationSlider .sl-button .MuiButton-startIcon {
    margin-right: 3px;
}
.meditationSlider .sl-button .MuiButton-startIcon svg {
    font-size: 16px !important;
}

.slick-slide {
    width: 150px;
}

.audio-icon {
    position: absolute;
    right: 20px;
}

.button-style {
    background: none;
    border: none;
    cursor: pointer;
}
@keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.custom-skelton{
    width:150px;
    height:230px !important;
    border: 0px solid transparent;
    border-radius: 10px;
    transform: none !important;
}
.arrow-container{
    width:110px;
    text-align: right;
}

.slick-prev {
    /*right: -11% !important;*/
    top: -22px !important;
    /* z-index: 99999; */
    background: transparent;
    position: absolute;
    /*left: 94% !important;*/
}
.slick-next {
    /*right: 0% !important;*/
    top: -22px !important;
    right: 0px !important;
}
.slick-prev {
    background-image: url(../../../assets/images/arrowLeft.png) !important;
    background-size: cover !important;
    width:35px !important;
    height:35px !important;
    right: 20px !important;
    left: auto;
}
.slick-next {
    background-image: url(../../../assets/images/arrowRight.png) !important;
    background-size: cover !important;
    width:35px !important;
    height:35px !important;
}
.slick-prev:before, .slick-next:before {
    content:"" !important;
    font-size: 40px !important;
    opacity: 1  !important;
    color: #403c3c !important;

}
.slick-next.slick-disabled,.slick-prev.slick-disabled{
    opacity:0.3;
}
@media screen and (max-width: 1000px) {
    .slick-prev {
        /*right: -11% !important;*/
        /*top: -22px !important;*/
        /* z-index: 99999; */
        background: transparent;
        position: absolute;
        /*left: 90% !important;*/
    }
    .slick-next {
        /*right: 0% !important;*/
        /*top: -22px !important;*/
    }
}
@media screen and (max-width: 600px) {
    .arrow-container{
        width: 242px;
        text-align: right;
        position: absolute;
        right: -13px;
        /* top: 10px; */
        bottom: -10px;
    }
    .tagContainer{
        margin-bottom: 20px !important;
    }
    .slick-prev {

        /*top: -20px !important;*/
        /*left: 77% !important;*/
    }
    .slick-next {
        /*right: 3% !important;*/
        /*top: -20px !important;*/
    }
}

@media screen and (min-width: 1700px) {
    .myCreatedMeditation .slick-prev{
        /*left: 86% !important;*/

    }
    .myCreatedMeditation .slick-next {
        /*right: 9% !important;*/

    }
}
@media screen and (max-width: 1100px) {
    .myCreatedMeditation .slick-prev{
        /*left: 82% !important;*/

    }
    .myCreatedMeditation .slick-next {
        /*right: 10% !important;*/

    }
}
@media screen and (max-width: 900px) {
    .myCreatedMeditation .slick-prev{
        /*left: 78% !important;*/

    }
    .myCreatedMeditation .slick-next {
        /*right: 13% !important;*/

    }
}
@media screen and (max-width: 600px) {
    .myCreatedMeditation .slick-prev{
        /*left: 81% !important;*/
        /*top:-12px !important*/
    }
    .myCreatedMeditation .slick-next {
        /*right: 2% !important;*/
        /*top: -13px !important;*/
    }
}
@media screen and (max-width: 415px) {
    .myCreatedMeditation .slick-prev{
        /*left: 64% !important;*/
        /*top:-15px !important*/
    }
    .myCreatedMeditation .slick-next {
        /*right: 18% !important;*/
        /*top: -15px !important;*/
    }
    .meditationContainer{
        margin-top:40px !important;
    }
}

