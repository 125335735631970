
.inputs-labels{
    padding-right: 155px;
}

.MuiInputLabel-standard,
.MuiFormLabel-root.Mui-focused{
    color: var(--sl-deep-purple);
}
.contactFormField label{
color: #514C54 !important;
border-bottom: none;
font-family: Figtree !important;
}
.seding-report-messege-form .MuiInputBase-root{
background-color: rgba(0, 0, 0, 0) !important;
}
textarea{
    width: 100%;
    padding:12px;
    resize:both;
    cursor:pointer;
}


/* .form-for-sending-mail{
    max-width: 420px;
} */


.email-input-field{
    /* border:1px solid crimson;
    width: 100%; */

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font: 15px/1 'Open Sans', sans-serif;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    min-width: 420px;
    background-color: #ddd;
    border: none;
    padding: 10px 11px 11px 11px;
    border-radius: 3px;
    box-shadow: none;
    outline: none;
    margin: 0;
    box-sizing: border-box;
    border:none;
    font-family: Figtree !important;
}

.text-input-field-button{
    width: 100%;
    height: 64px;
}

.input-text-field{
    background-color: #FFFFFF ;
    color: #514C54;
    border:none;
    font-family: Figtree !important;

}

.forgot-password-email-input::before{
    border:none !important;
}
.forgot-password-email-input-message {
    min-height: 120px;
    background-color: #FFFFFF ;
    color: #514C54;
    border-radius:10px;
    margin:20px;
    border:none;
    font-family: Figtree !important;

}

.contactFormField .Mui-error{
    color: var(red);
    font-family: "Figtree" !important;
    /* padding-top: 10px !important; */
    background-color: transparent;
    /* margin-left: -2px; */
}
.contactFormField .Mui-error::after{
    padding-top: 5px !important;
    /* margin-bottom: 10px !important; */

}
.css-1kuusto-MuiFormControl-root-MuiTextField-root{
    margin-bottom:5px !important;
}
.seding-report-messege-form .MuiFilledInput-underline:after{
    border-bottom: none !important;
}
.seding-report-messege-form .MuiFilledInput-underline:before{
    border-bottom: none !important;
}
.contactFormField {
    color: var(--sl-deep-purple) !important;
    background-color: white;
    border-radius: 10px !important;
    border-bottom: none !important;
}
.contactFormField:before{
    color: var(--sl-deep-purple) !important;
}
.contactFormField:after {
    color: var(--sl-deep-purple) !important;
    background-color: white;
    /* border-radius: 10px ; */
    border-bottom:none !important;
    border-bottom: none;
}

 @media only screen and (max-width:480px) {
    .email-input-field{
    width: 100%;
       min-width: auto;

    }

 }

 .seding-report-messege-form .MuiInputBase-input {
    color: var(--sl-deep-purple) !important;
    border:none !important;
    font-family: Figtree !important;
 }
.css-19mk8g1-MuiInputBase-root-MuiFilledInput-root:before{
    border-bottom: none !important;
}
 .css-19mk8g1-MuiInputBase-root-MuiFilledInput-root:after{
    border-bottom: white !important;
 }
 .css-1gzyby-MuiInputBase-root-MuiFilledInput-root.Mui-error::before{
    border-bottom: none ;
 }
 .css-1gzyby-MuiInputBase-root-MuiFilledInput-root.Mui-error:after{
    border-bottom: none ;
 }

 .SLButton>p{
    letter-spacing: -.3px !important;
 }
 .MuiInputLabel-formControl{
    letter-spacing: -0.3px !important;

 }
